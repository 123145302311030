import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAdminContext } from "../../../../context/admin-context";
import ManageForm from "../../AdminCommon/ManageForm";
import ManageFieldPopupForm from "../FieldTypes/ManageFieldPopupForm";
import { useLoading } from "../../../../hooks/useLoading";

const CategoryFieldsForm = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const { setIsLoading } = useLoading();
  const adminContext = useAdminContext();
  const { category: categoryName } = routeParams;

  const [showCreateFieldWindow, setShowCreateFieldWindow] = useState(false);
  const [showEditFieldWindow, setShowEditFieldWindow] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    adminContext.getObjectFields(categoryName, true).then(
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        if (success) {
          setFields(responseData || []);
        } else {
          console.log(message);
        }
      },
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        console.log(message);
      }
    );
  }, [categoryName]);

  const handleOnCancelClick = () => {
    navigate(-1);
  };

  const handleOnSaveClick = (data) => {
    console.log("save Data", data);
    adminContext.updateCategory(categoryName, { fields }).then(
      (response) => {
        navigate(-1);
      },
      (response) => {
        alert(response.message);
      }
    );
  };

  const handleOnFieldChange = (fieldName, value) => {};

  const handleOnNewFieldCreate = (field) => {
    console.log("dfasfshfk",field)
    setFields([...fields, field]);
  };

  const handleOnEditField = (field) => {
    setEditingField(field);
    setShowEditFieldWindow(true);
  };

  const handleOnDeleteField = (field) => {
    const newFields = [...fields];
    const index = newFields.findIndex((f) => f.name === field.name);

    if (index > -1) {
      newFields.splice(index, 1);
      setFields(newFields);
    }
  };

  const handleOnEditFieldSave = (field) => {
    const newFields = [...fields];
    const index = newFields.findIndex((f) => f.name === field.name);

    if (index > -1) {
      newFields[index] = field;
      setFields(newFields);
    }
  };

  return (
    <>
      <ManageForm
        title={"Manage " + categoryName + " fields"}
        fieldSections={[{ fields }]}
        onSave={handleOnSaveClick}
        onCancel={handleOnCancelClick}
        onFieldValueChange={handleOnFieldChange}
        onEditField={handleOnEditField}
        onDeleteField={handleOnDeleteField}
      >
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 2 }}>
          <Button
            type="button"
            startIcon={<AddIcon />}
            sx={{ borderRadius: 2 }}
            variant="outlined"
            onClick={() => setShowCreateFieldWindow(true)}
          >
            Create New Field
          </Button>
        </Box>
      </ManageForm>
      <ManageFieldPopupForm
        open={showCreateFieldWindow}
        setOpen={setShowCreateFieldWindow}
        onSave={handleOnNewFieldCreate}
      />
      <ManageFieldPopupForm
        field={editingField}
        editingMode={true}
        open={showEditFieldWindow}
        setOpen={setShowEditFieldWindow}
        onSave={handleOnEditFieldSave}
      />
    </>
  );
};

export default CategoryFieldsForm;

import { Backdrop, CircularProgress } from "@mui/material";
import { useLoading } from "../../hooks/useLoading";

const LoadingIndicator = () => {
  const { isLoading, loadingText } = useLoading();

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
          flexDirection: "column",
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <div>{loadingText}</div>
      </Backdrop>
    </>
  );
};

export default LoadingIndicator;

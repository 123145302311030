import { TextField } from "@mui/material";
import { getDateFormat } from "../../admin/AdminGenUtility";

const CustomInputDate = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const { type = "date", required = false, value = "" } = field;

  const handleOnChange = (event) => {
    let dateSelect = event.target.value;
    // date fomr using regular exressino
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(dateSelect)) {
      let dateInMillisecond = new Date(dateSelect).getTime();
      onChange(dateInMillisecond);
    }
  };

  return (
    <TextField
      type={type}
      variant="outlined"
      required={required}
      size="small"
      onChange={handleOnChange}
      value={getDateFormat(value)}
      inputProps={{ min: "2000-01-01", max: "2030-12-31" }}
    ></TextField>
  );
};

export default CustomInputDate;

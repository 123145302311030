import { Box, List } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LeftPanelButton from "./LeftPanelButton";
import LogoutConfirmDialog from "../AdminCommon/LogoutConfirmDialog";
import { useAdminContext } from "../../../context/admin-context";

const LeftPanel = (props) => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [menuOptions, setMenuOptions] = useState([
    {
      text: "Dashboard",
      navigateTo: "/admin/admin-dashboard",
      icon: <DashboardIcon />,
    },
  ]);

  useEffect(() => {
    adminContext.getCategoryList().then((response) => {
      const list = [
        {
          text: "Dashboard",
          navigateTo: "/admin/admin-dashboard",
          icon: <DashboardIcon />,
        },
      ];
      const categories = response.responseData;
      categories.map((cat) => {
        list.push({
          text: cat.name,
          navigateTo: "/admin/" + cat.name,
          icon: <DashboardIcon />,
        });
      });

      setMenuOptions(list);
    });
  }, []);
  // console.log(menuOptions);
  const endMenuOptions = [
    {
      text: "Settings",
      icon: <SettingsIcon />,
      onClick: () => {
        navigate("/admin/settings/manage-type");
      },
      itemList: [
        {
          icon: <SettingsIcon />,
          text: "Manage Categories",
          navigateTo: "/admin/settings/manage-categories",
        },
        {
          icon: <SettingsIcon />,
          text: "Manage Type",
          navigateTo: "/admin/settings/manage-type",
        },
        {
          icon: <SettingsIcon />,
          text: "Manage Photo",
          navigateTo: "/admin/settings/manage-photo",
        },
      ],
    },
    {
      text: "My Account",
      navigateTo: "/admin/my-account",
      icon: <AccountCircleIcon />,
    },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        setOpenDialog(true);
      },
    },
  ];

  const handleOnConfirm = () => {};
  const handleOnCancel = () => {};

  return (
    <Box
      sx={{
        ...props.sx,
        minWidth: 200,
        backgroundColor: "#EFEFEF",
        overflowY: "auto",
      }}
    >
      <List>
        {menuOptions.map((option, index) => (
          <LeftPanelButton key={index} {...option} index={index} />
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />

      <List>
        {endMenuOptions.map((option, index) => (
          <LeftPanelButton key={index} {...option} index={index} />
        ))}
      </List>

      <LogoutConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title="Logout"
        contentText="Are you sure you want to logout?"
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
      />
    </Box>
  );
};

export default LeftPanel;

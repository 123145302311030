import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const LeftPanelButton = (props) => {
  const {
    text: actionText,
    icon: actionIcon,
    navigateTo,
    onClick: actionOnClick = () => {},
    itemList: actionItemList = [],
  } = props;

  const [openCollapse, setOpenCollapse] = useState(false);

  const handleOnListItemButtonClick = () => {
    setOpenCollapse(!openCollapse);
    actionOnClick();
  };

  return (
    <List>
      <NavLink
        to={navigateTo}
        style={({ isActive }) => ({
          color: isActive && navigateTo ? "#ffffff" : "#000000",
          display: "block",
          fontWeight: "bold",
          textDecoration: "none",
          backgroundColor: isActive && navigateTo ? "#0078d2" : "transparent",
        })}
      >
        <ListItemButton onClick={handleOnListItemButtonClick}>
          <ListItemIcon>{actionIcon}</ListItemIcon>
          <ListItemText sx={{ fontWeight: "bold" }} primary={actionText} />
          {actionItemList.length > 0 &&
            (openCollapse ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </NavLink>

      {actionItemList.length > 0 && (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {actionItemList.map((actionItem) => (
              <NavLink
                to={actionItem.navigateTo}
                style={({ isActive }) => ({
                  color:
                    isActive && actionItem.navigateTo ? "#ffffff" : "#000000",
                  display: "block",
                  fontWeight: "bold",
                  textDecoration: "none",
                  backgroundColor:
                    isActive && actionItem.navigateTo
                      ? "#0078d2"
                      : "transparent",
                })}
              >
                <ListItemButton
                  key={actionItem.text}
                  sx={{ pl: 4 }}
                  onClick={actionItem.onClick}
                >
                  <ListItemIcon>{actionItem.icon}</ListItemIcon>
                  <ListItemText primary={actionItem.text} />
                </ListItemButton>
              </NavLink>
            ))}
          </List>
        </Collapse>
      )}
    </List>
  );
};

export default LeftPanelButton;

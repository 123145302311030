import { Box, Button, Container, Divider } from "@mui/material";
import AdminWrapper from "../../AdminCommon/AdminWrapper";
import CustomToolbarActions from "../../AdminCommon/CustomToolbarActions";
import { useNavigate, useParams } from "react-router-dom";
import LinkDetails from "./LinkDetails";
import FormDetails from "./FormDetails";
import { useEffect, useState } from "react";
import { useAdminContext } from "../../../../context/admin-context";
import { useLoading } from "../../../../hooks/useLoading";
import EditIcon from '@mui/icons-material/Edit';

const CategoryDetails = (props) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const { category } = routeParams;
  const adminContext = useAdminContext();

  const { setIsLoading, setLoadingText } = useLoading();
  const [categoryDetails, setCategoryDetails] = useState({});
  const [links, setLinks] = useState([]);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    adminContext.getCategory(category).then(
      (response) => {
        setIsLoading(false);
        if (response.success) {
          setCategoryDetails(response.responseData);
        }
      },
      (response) => {
        setIsLoading(false);
      }
    );
  }, [category]);

  const handleOnManageFieldClick = () => {
    navigate("/admin/settings/manage-categories/" + category + "/fields");
  };
  const handleOnCancelClick = () => {
    navigate(-1);
  };

  return (
    <AdminWrapper>
      <CustomToolbarActions
        title={categoryDetails.singularName + " Details"}
        // actions={actionList}
        showBackButton={true}
        onBackButtonClick={handleOnCancelClick}
      />
      <Container>
        <Box>Name: {categoryDetails.name}</Box>

        <Box>Singular Name: {categoryDetails.singularName}</Box>
        <Box>Field Title : {categoryDetails.singularName}
        <Button size="small" startIcon={<EditIcon/>}>Change</Button>
        </Box>


        <Divider sx={{ my: 3 }} />
        <Button
          variant="outlined"
          type="button"
          onClick={handleOnManageFieldClick}
        >
          Manage Fields
        </Button>

        <Divider sx={{ my: 3 }} />
        <LinkDetails />

        <Divider sx={{ my: 3 }} />
        <FormDetails />
      </Container>
    </AdminWrapper>
  );
};
export default CategoryDetails;

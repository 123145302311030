import { BrowserRouter, Route, Routes } from "react-router-dom";
import Setting from "./Setting/Setting";
import AdminDashboard from "./AdminHome/AdminDashboard";
import NewFieldType from "./Setting/FieldTypes/NewFieldType";
import FieldTypeList from "./Setting/FieldTypes/FieldTypeList";
import CategoryList from "./Setting/Categories/CategoryList";

import ObjectList from "./Objects/ObjectList";
import ViewObject from "./Objects/ViewObject";
import EditObject from "./Objects/EditObject";
import NewObject from "./Objects/NewObject";
import CategoryFieldsForm from "./Setting/Categories/CategoryFieldsForm";
import CategoryDetails from "./Setting/Categories/CategoryDetails";

const AdminBrowserRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/admin-dashboard" element={<AdminDashboard />} />

        <Route path="/admin/:categoryName" element={<ObjectList />} />
        <Route path="/admin/:categoryName/:id" element={<ViewObject />} />
        <Route path="/admin/:categoryName/edit/:id" element={<EditObject />} />
        <Route path="/admin/:categoryName/new" element={<NewObject />} />

        <Route path="/admin/settings" element={<Setting />} />
        <Route
          path="/admin/settings/manage-categories"
          element={<CategoryList />}
        />

        <Route
          path="/admin/settings/manage-categories/:category"
          element={<CategoryDetails />}
        />
        <Route
          path="/admin/settings/manage-categories/:category/fields"
          element={<CategoryFieldsForm />}
        />
        <Route
          path="/admin/settings/manage-categories/:category/forms"
          element={<CategoryFieldsForm />}
        />

        <Route path="/admin/settings/manage-type" element={<FieldTypeList />} />
        <Route path="/admin/settings/manage-photo" element={<Setting />} />
        <Route
          path="/admin/settings/manage-type/new-type"
          element={<NewFieldType title="Title" buttonLabel="Add Field" />}
        />

        <Route path="/admin/my-account" element={<Setting />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AdminBrowserRouter;

import React, { createContext, useEffect, useState } from "react";

export const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading, please wait...");

  // Context value
  const contextValue = {
    isLoading,
    setIsLoading,
    loadingText,
    setLoadingText,
  };

  useEffect(() => {
    if (!isLoading) {
      setLoadingText("Loading, please wait...");
    }
  }, [isLoading]);

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useEffect, useState } from "react";

const CustomInputCheckboxGroup = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const { required = false, options = [], value = [] } = field;

  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    setCheckedOptions(value);
  }, [String(value)]);

  const handleOnChange = (event) => {
    const { value: option, checked } = event.target;

    let newOptions = [...checkedOptions];

    if (checked) {
      newOptions.push(option);
    } else {
      newOptions = newOptions.filter((opt) => opt !== option);
    }
    setCheckedOptions(newOptions);

    onChange(newOptions);
  };

  return (
    <FormControl>
      <FormGroup row>
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={checkedOptions.includes(option)}
                  onChange={handleOnChange}
                  value={option}
                />
              }
              label={option}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default CustomInputCheckboxGroup;

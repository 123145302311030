import { createContext, useContext, useState } from "react";
import {
  createNewDocument,
  deleteDocument,
  fetchDataPaginated,
  fetchDocumentById,
  updateExistingDocument,
} from "./dbQueries";
import { FIELDS_TO_CONSIDER_WHILE_SAVING } from "../components/admin/AdminAppConstant";

// import { db } from "../firebaseConfig";
// import { doc, getDoc } from "firebase/firestore";

const AdminDispatchContext = createContext(undefined);
const AdminContext = createContext({
  getCategoryList: () => {},
  getCategory: (categoryName) => {},
  updateCategory: (categoryName, updatedData) => {},
  // getCategoryFields: (type, id) => {},

  getFieldTypeList: () => {},
  createFieldType: (details) => {},
  getFieldType: (id) => {},

  getObjectList: (type, filter, forceLoad) => {},
  createObject: (type, details) => {},
  getObject: (type, id) => {},
  editObject: (type, id, updatedDetails) => {},
  deleteObject: (type, id) => {},
  getObjectFields: (type) => {},
  getOtherInformationFields: (fieldType) => {},
  getTableNameByCatId: (categoryId) => {},
  getCategoryNameById: (categoryId) => {},
});

export const useAdminContext = () => useContext(AdminContext);

const AdminContextProvider = (props) => {
  const [allObjects, setAllObjects] = useState({});
  const [allObjectFields, setAllObjectFields] = useState({});
  const [categoryMetadata, setCategoryMetadata] = useState([]);

  const getCollectionName = (name) => {
    return name ? name.toUpperCase() + "_DATA" : "";
  };

  /************* CATEGORIES *************/
  const getCategoryList = (forceLoad) => {
    const promise = new Promise((resolve, reject) => {
      if (!forceLoad && categoryMetadata && categoryMetadata.length > 0) {
        resolve({
          success: true,
          responseData: categoryMetadata,
          message: "Categories are loaded successfully.",
        });
        return;
      }

      fetchDataPaginated("CATEGORY_METADATA", [], "", 100)
        .then(({ data, lastDoc }) => {
          // Store lastDoc for fetching next page
          setCategoryMetadata(data);
          // console.log(data);
          resolve({
            success: true,
            responseData: data,
            message: "Categories are loaded successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const getCategory = (categoryName, updatedData) => {
    const promise = new Promise((resolve, reject) => {
      const category =
        categoryMetadata &&
        categoryMetadata.find((cat) => cat.name === categoryName);
      const id = category ? category.id : categoryName;
      fetchDocumentById("CATEGORY_METADATA", id)
        .then((doc) => {
          if (doc) {
            console.log("Document data:", doc);
            resolve({
              success: true,
              responseData: doc,
              message: "Object is loaded successfully.",
            });
          } else {
            reject({
              success: false,
              responseData: null,
              message: "The object you are trying to access does not exists.",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };
  const getCategoryNameById = (categoryId) => {
    const category =
      categoryMetadata &&
      categoryMetadata.find(
        (cat) => cat.name === categoryId || cat.id === categoryId
      );
    if (category) {
      return category.name;
    }
  };

  const getTableNameByCatId = (categoryName) => {
    const category =
      categoryMetadata &&
      categoryMetadata.find(
        (cat) => cat.name === categoryName || cat.id === categoryName
      );
    if (category) {
      return getCollectionName(category.name);
    }
  };

  const restructureFields = (fields = []) => {
    const newFields = [];
    fields.map((fld) => {
      const obj = {};
      FIELDS_TO_CONSIDER_WHILE_SAVING.map((property) => {
        if (fld.hasOwnProperty(property)) {
          obj[property] = fld[property];
        }
      });
      newFields.push(obj);
    });
    return newFields;
  };

  const updateCategory = (categoryName, updatedData) => {
    const promise = new Promise((resolve, reject) => {
      const category = categoryMetadata.find(
        (cat) => cat.name === categoryName || cat.id === categoryName // changes cat.id === categoryName
      );
      const id = category ? category.id : "";
      if (updatedData.fields) {
        updatedData.fields = restructureFields(updatedData.fields);
      }

      updateExistingDocument("CATEGORY_METADATA", id, updatedData)
        .then((docId) => {
          console.log("Upated document with ID: ", docId);
          resolve({
            success: true,
            responseData: { id: docId },
            message: "Object is updated successfully.",
          });

          //update local metadata
          getCategoryList(true);
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  /************* FIELD TYPE LIST *************/
  const getFieldTypeList = () => {
    const promise = new Promise((resolve, reject) => {
      fetchDataPaginated("CUSTOM_TYPE_METADATA", [], "", 100)
        .then(({ data, lastDoc }) => {
          // Store lastDoc for fetching next page
          resolve({
            success: true,
            responseData: data,
            message: "Custom types are loaded successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const createFieldType = (details) => {
    const promise = new Promise((resolve, reject) => {
      createNewDocument("CUSTOM_TYPE_METADATA", details)
        .then((docId) => {
          console.log("Document written with ID: ", docId);
          resolve({
            success: true,
            responseData: { id: docId },
            message: "Object is created successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const getFieldType = (id) => {
    const promise = new Promise((resolve, reject) => {
      fetchDocumentById("CUSTOM_TYPE_METADATA", id)
        .then((doc) => {
          if (doc) {
            console.log("Document data:", doc);
            resolve({
              success: true,
              responseData: doc,
              message: "Object is loaded successfully.",
            });
          } else {
            reject({
              success: false,
              responseData: null,
              message: "The object you are trying to access does not exists.",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  /************* OBJECTS *************/
  const fetchObjectList = (type, filter) => {
    const promise = new Promise((resolve, reject) => {
      fetchDataPaginated(getCollectionName(type), [], "", 100)
        .then(({ data, lastDoc }) => {
          // Store lastDoc for fetching next page
          const newData = { ...allObjects };
          newData[type] = data;
          setAllObjects(newData);
          resolve({
            success: true,
            responseData: data,
            message: type + " are loaded successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const getObjectList = (type, filter, forceLoad) => {
    if (
      !allObjects ||
      Object.keys(allObjects).length === 0 ||
      !allObjects[type] ||
      forceLoad
    ) {
      return fetchObjectList(type, filter);
    }

    const promise = new Promise((resolve, reject) => {
      resolve({
        success: true,
        responseData: allObjects[type],
        message: "Object list loaded successfully.",
      });
    });

    return promise;
  };

  const createObject = (type, requestData) => {
    const promise = new Promise((resolve, reject) => {
      createNewDocument(getCollectionName(type), requestData)
        .then((docId) => {
          console.log("Document written with ID: ", docId);
          resolve({
            success: true,
            responseData: { id: docId },
            message: "Object is created successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };
  const getObject = (type, id) => {
    const promise = new Promise((resolve, reject) => {
      fetchDocumentById(getCollectionName(type), id)
        .then((doc) => {
          resolve({
            success: true,
            responseData: doc,
            message: "Object is loaded successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const editObject = (type, id, updatedData) => {
    const promise = new Promise((resolve, reject) => {
      updateExistingDocument(getCollectionName(type), id, updatedData)
        .then((doc) => {
          resolve({
            success: true,
            responseData: doc,
            //     responseData: { id, ...updatedData },
            message: "Object is loaded successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });
    return promise;
  };

  const deleteObject = (type, id) => {
    const promise = new Promise((resolve, reject) => {
      deleteDocument(getCollectionName(type), id)
        .then((doc) => {
          resolve({
            success: true,
            responseData: doc,
            message: "Object is deleted successfully.",
          });
        })
        .catch((error) => {
          console.error(error);
          reject({
            success: false,
            responseData: null,
            message: error,
          });
        });
    });

    return promise;
  };

  const getObjectFields = (type) => {
    if (
      !allObjectFields ||
      Object.keys(allObjectFields).length === 0 ||
      !allObjectFields[type]
    ) {
      return fetchObjectFields(type);
    }

    const promise = new Promise((resolve, reject) => {
      resolve({
        success: true,
        responseData: allObjectFields[type],
        message: "Object fields loaded successfully.",
      });
    });

    return promise;
  };

  const fetchObjectFields = (type) => {
    const promise = new Promise((resolve, reject) => {
      const record = categoryMetadata.find(
        (rec) => rec.name.toLowerCase() === type.toLowerCase()
      );

      if (record) {
        const data = record.fields;

        const newData = { ...allObjectFields };
        newData[type] = data;
        setAllObjectFields(newData);

        resolve({
          success: true,
          responseData: data,
          message: "Object fields loaded successfully.",
        });
      } else {
        reject({
          success: false,
          responseData: [],
          message: "Object fields not loaded.",
        });
      }
    });

    return promise;
  };
  //
  // const getCategoryFields = (type, id) => {
  //   const promise = new Promise((resolve, reject) => {
  //     fetchDocumentById(getCollectionName(type), id)
  //       .then((doc) => {
  //         resolve({
  //           success: true,
  //           responseData: doc,
  //           message: "Object is loaded successfully.",
  //         });
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         reject({
  //           success: false,
  //           responseData: null,
  //           message: error,
  //         });
  //       });
  //   });

  //   return promise;
  // };
  //
  /************* OTHER INFORMATION *************/
  const getOtherInformationFields = (fieldType) => {
    const promise = new Promise((resolve, reject) => {
      resolve({
        success: true,
        responseData: [
          {
            name: "sensorSize",
            text: "Sensor Size",
            type: "singleLine",
            required: false,
          },
          {
            name: "resolutions",
            text: "Resolutions",
            type: "multiLine",
            required: false,
          },
        ],
        message: "Customer list loaded successfully.",
      });
    });

    return promise;
  };

  return (
    <AdminContext.Provider
      value={{
        getCategoryList: getCategoryList,
        getCategory: getCategory,
        updateCategory: updateCategory,
        getFieldTypeList: getFieldTypeList,
        createFieldType: createFieldType,
        getFieldType: getFieldType,
        //
        // getCategoryFields: getCategoryFields,

        getObjectList: getObjectList,
        createObject: createObject,
        getObject: getObject,
        editObject: editObject,
        deleteObject: deleteObject,

        getObjectFields: getObjectFields,
        getOtherInformationFields: getOtherInformationFields,
        getTableNameByCatId: getTableNameByCatId,
        getCategoryNameById: getCategoryNameById,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export { AdminContextProvider, AdminContext, AdminDispatchContext };

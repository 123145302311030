import { TextField } from "@mui/material";

const CustomInputText = (props) => {
  const { field = {}, onChange = () => {} } = props;

  const {
    type = "text",
    required = false,
    fullWidth = false,
    multi = false,
    value = "",
    rows = 4,
    disabled,
  } = field;
  return (
    <TextField
      type={type}
      variant="outlined"
      required={required}
      size="small"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      // onBlur={(event) => onChange(event.target.value)}
      fullWidth={fullWidth}
      multiline={multi}
      rows={rows}
      disabled={disabled}
    ></TextField>
  );
};

export default CustomInputText;

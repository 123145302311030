import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Home from "./components/Home/Home";
import AdminBrowserRouter from "./components/admin/AdminBrowserRouter";
import { LoadingContextProvider } from "./context/loading-context";
import LoadingIndicator from "./components/common/LoadingIndicator";
import { AdminContextProvider } from "./context/admin-context";

function App() {
  return (
    <Box sx={{ display: "flex" }}>
      <LoadingContextProvider>
        <LoadingIndicator />
        <AdminContextProvider>
          <AdminBrowserRouter />
        </AdminContextProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </LoadingContextProvider>
    </Box>
  );
}

export default App;

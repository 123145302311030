import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlspgCbZPkuz2pTkn3QQoVw2vCFawDKxE",
  authDomain: "rentocamera-live.firebaseapp.com",
  projectId: "rentocamera-live",
  storageBucket: "rentocamera-live.appspot.com",
  messagingSenderId: "232222062349",
  appId: "1:232222062349:web:6db198c67de04ab8566244",
  measurementId: "G-N5N36TW34G"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
 
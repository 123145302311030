import AdminWrapper from "../AdminCommon/AdminWrapper";

const AdminDashboard = () => {
  return (
    <AdminWrapper>
      <h3>Admin Dashboard</h3>
    </AdminWrapper>
  );
};

export default AdminDashboard;

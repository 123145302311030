import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const LogoutConfirmDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    title = "",
    contentText = "",
    onConfirm = () => {},
    onCancel = () => {},
  } = props;

  const handleOnClose = () => {
    setOpenDialog(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={handleOnClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          No
        </Button>
        <Button variant="contained" onClick={handleOnConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmDialog;

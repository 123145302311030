import { TextField } from "@mui/material";

const CustomInputNumber = (props) => {
  const { field = {}, onChange = () => {} } = props;

  const {
    type = "number",
    required = false,
    fullWidth = false,
    value = "",
  } = field;

  return (
    <TextField
      type={type}
      variant="outlined"
      required={required}
      size="small"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      fullWidth={fullWidth}
    ></TextField>
  );
};

export default CustomInputNumber;

import { Box, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const CustomDynamicOptions = (props) => {
  const { onChange = () => {}, required, field } = props;
  const { fullWidth } = field;
  const [options, setOptions] = useState([""]);

  useEffect(() => {
    onChange(options);
  }, [String(options)]);

  const removeOption = (index) => {
    let o = [...options];

    if (o.length > 1) {
      o.splice(index, 1);
      setOptions(o);
    }
  };
  const addOption = (index) => {
    setOptions([...options, ""]);
  };

  return (
    <Box width={"100%"}>
      {options.map((option, index) => {
        return (
          <Box sx={{ paddingY: 1, display: "flex" }}>
            <TextField
              type="text"
              value={option}
              size="small"
              required={required}
              fullWidth={fullWidth}
              onChange={(e) => {
                let o = [...options];
                o[index] = e.target.value;
                setOptions(o);
              }}
            />
            {options.length > 1 && (
              <IconButton onClick={() => removeOption(index)}>
                <RemoveIcon />
              </IconButton>
            )}
            <IconButton onClick={() => addOption(index)}>
              <AddIcon />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomDynamicOptions;

import { Close, SaveOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import AdminWrapper from "./AdminWrapper";
import { getComponent } from "../AdminGenUtility";
import CustomToolbarActions from "./CustomToolbarActions";

const ManageForm = (props) => {
  const {
    title,
    values = {},
    fieldSections = [],
    onSave,
    onSubmit = () => {},
    onCancel = () => {},
    onFieldValueChange = () => {},
    onEditField,
    onDeleteField,
  } = props;

  const [data, setData] = useState({});

  useEffect(() => {
    setData(values);
  }, [String(values)]);

  const handleOnCancelClick = () => {
    onCancel();
  };

  const handleOnSubmitClick = (e) => {
    e.preventDefault();
    onSubmit(data);
  };

  const handleOnSaveClick = () => {
    if (onSave) onSave(data);
  };

  const actionList = [
    { text: "Cancel", icon: <Close />, onClick: handleOnCancelClick },
    {
      text: "Save",
      icon: <SaveOutlined />,
      type: "submit",
    },
  ];

  if (onSave) {
    delete actionList[1].type;
    actionList[1].onClick = handleOnSaveClick;
  }

  const handleOnChange = (fieldName, value) => {
    if (fieldName) {
      setData({ ...data, [fieldName]: value });
      onFieldValueChange(fieldName, value);
    }
  };

  return (
    <AdminWrapper>
      <form onSubmit={handleOnSubmitClick} style={{ height: "100%" }}>
        <CustomToolbarActions
          title={title}
          actions={actionList}
          showBackButton={true}
          onBackButtonClick={handleOnCancelClick}
        />
        <Box
          sx={{
            overflowY: "auto",
            height: "calc(100% - 70px - 30px)",
            padding: "15px",
          }}
        >
          {fieldSections.map((section) => {
            return (
              <Box>
                {section.header &&
                  section.fields &&
                  section.fields.length > 0 && <h2>{section.header}</h2>}
                {section.fields &&
                  section.fields.map((field) => {
                    let newField = { ...field };
                    newField.value = data[field.name];
                    return getComponent(newField, {
                      onChange: (value) => {
                        handleOnChange(field.name, value);
                      },
                      onEditField: onEditField,
                      onDeleteField: onDeleteField,
                    });
                  })}
              </Box>
            );
          })}
        </Box>
        {props.children}
      </form>
    </AdminWrapper>
  );
};
export default ManageForm;

import { Checkbox } from "@mui/material";

const CustomInputCheckbox = (props) => {
  const { field = {}, onChange = () => {} } = props;

  return (
    <Checkbox
      onChange={(event) => onChange(event.target.checked)}
      checked={field.value === true}
    />
  );
};

export default CustomInputCheckbox;

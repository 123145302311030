import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const CustomRadioGroup = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const { required = false, options = [], value = "" } = field;

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        row
        onChange={handleOnChange}
        defaultValue={value}
        required={required}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              value={option}
              control={<Radio size="small" />}
              label={option}
              checked={option === value}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;

import { FIELD_TYPES, TYPES } from "../../AdminAppConstant";

export const FIELD_TYPE_FIELDS = [
  {
    name: "type",
    label: "Field Type",
    type: TYPES.SELECT_LIST,
    required: true,
    options: FIELD_TYPES,
  },
  {
    name: "label",
    label: "Field Label",
    type: TYPES.TEXT,
    required: true,
  },
  {
    name: "required",
    label: "Required",
    type: TYPES.CHECKBOX,
  },
];

export const FIELD_TYPE_IS_FULLWIDTH_FIELD = {
  name: "fullWidth",
  label: "Use full width",
  type: TYPES.CHECKBOX,
};

export const FIELD_TYPE_IS_MULTI_FIELD = {
  name: "multi",
  label: "Allow Multiple",
  type: TYPES.CHECKBOX,
};

export const FIELD_TYPE_DYNAMIC_OPTION_FIELD = {
  name: "options",
  label: "Options",
  type: TYPES.DYNAMIC_OPTIONS,
  required: true,
};

export const FIELD_TYPE_IS_URL_FIELD = {
  name: "isUrl",
  label: "Is URL",
  type: TYPES.CHECKBOX,
};

export const FIELD_TYPE_CATEGORY_LIST_FIELD = {
  name: "category",
  label: "Category",
  type: TYPES.SELECT_LIST,
  options: [],
  delayedLoading: true,
};

export const FIELD_TYPE_CATEGORY_FIELDS_LIST_FIELD = {
  name: "categoryField",
  label: "Field",
  type: TYPES.SELECT_LIST,
  options: [],
  delayedLoading: true,
};

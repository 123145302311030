import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { FIELD_TYPES, TYPES } from "./AdminAppConstant";

import {
  CustomInputText,
  CustomInputNumber,
  CustomInputDate,
  CustomInputCheckbox,
  CustomSelect,
  CustomRadioGroup,
  CustomInputCheckboxGroup,
  CustomDynamicOptions,
  CustomRichTextEditor,
  CustomImage,
} from "../common/EditableComponents";
import { Delete, Edit } from "@mui/icons-material";
import { fetchDataPaginated, fetchDocumentById } from "../../context/dbQueries";
import CustomLinkSelect from "../common/EditableComponents/CustomLinkSelect";
import { Link } from "react-router-dom";

export const getComponent = (field, handlers = {}, textRef) => {
  let component = null;
  const { onChange = () => {}, onEditField, onDeleteField } = handlers;

  switch (field.type) {
    case TYPES.TEXT:
      component = <CustomInputText field={field} onChange={onChange} />;
      break;

    case TYPES.RICH_TEXT:
      component = <CustomRichTextEditor field={field} onChange={onChange} />;
      break;

    case TYPES.NUMBER:
      component = <CustomInputNumber field={field} onChange={onChange} />;
      break;

    case TYPES.DATE:
      component = <CustomInputDate field={field} onChange={onChange} />;
      break;

    case TYPES.CHECKBOX:
      component = <CustomInputCheckbox field={field} onChange={onChange} />;
      break;

    case TYPES.SELECT_LIST:
    case TYPES.SELECT_LIST_CUSTOM_TYPE:
      component = <CustomSelect field={field} onChange={onChange} />;
      break;

    case "linkField":
      component = <CustomLinkSelect field={field} onChange={onChange} />;
      break;

    case TYPES.CHECKBOX_GROUP:
      component = (
        <CustomInputCheckboxGroup field={field} onChange={onChange} />
      );
      break;

    case TYPES.RADIO_GROUP:
      component = <CustomRadioGroup field={field} onChange={onChange} />;
      break;

    case TYPES.DYNAMIC_OPTIONS:
      component = <CustomDynamicOptions field={field} onChange={onChange} />;
      break;

    case TYPES.CUSTOM_FIELD:
      component = field.multi ? (
        <CustomDynamicOptions field={field} onChange={onChange} />
      ) : (
        <CustomInputText field={field} onChange={onChange} />
      );
      break;

    case TYPES.IMAGE:
      component = <CustomImage field={field} onChange={onChange} />;

    default:
      break;
  }

  if (!component) return;

  return (
    <Container
      sx={{
        ":hover": {
          backgroundColor:
            onEditField && onDeleteField ? "#EFEFEF" : "transparent",
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "Bold",
            padding: "15px",
            textAlign: "right",
            width: 200,
          }}
        >
          {field.label}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <Box sx={{ width: "100%", display: "flex", paddingY: 1 }}>
          {component}
        </Box>
        {onEditField && onDeleteField && (
          <Box style={{ display: "flex" }}>
            <IconButton onClick={() => onEditField(field)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => onDeleteField(field)}>
              <Delete />
            </IconButton>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export const getToViewComponent = (field, fieldValue) => {
  let component = null;
  switch (field.type) {
    case "text":
      component = <Box>{fieldValue}</Box>;
      break;
    case "richText":
      component = <div dangerouslySetInnerHTML={{ __html: fieldValue }}></div>;
      break;
    case "number":
      component = <Box>{fieldValue}</Box>;
      break;
    case "date":
      component = <Box>{getDateFormat(fieldValue)}</Box>;
      break;
    case "selectList":
      component = <Box>{fieldValue}</Box>;
      break;
    case "radioGroup":
      component = <Box>{fieldValue}</Box>;
      break;
    case "customField":
      component = (
        <Box>
          <Link href={fieldValue} underline="hover" target="-blank">
            {fieldValue}
          </Link>
        </Box>
      );
      break;
    case "checkboxGroup":
      let commaSeparate =
        fieldValue && fieldValue.join ? fieldValue.join(", ") : "";
      component = <Box>{commaSeparate}</Box>;
      break;
    case "image":
      component = (
        <Box>
          <img
            src={fieldValue}
            alt={fieldValue}
            style={{ width: "100px", height: "100px" }}
          />
        </Box>
      );
      break;
    case "linkField":
      const links = [];
      let ids = [];
      let texts = [];
      if (fieldValue) {
        if (field.multi) {
          ids = fieldValue.id.split(",");
          texts = fieldValue.text.split(",");
        } else {
          ids = [fieldValue.id];
          texts = [fieldValue.text];
        }

        ids.map((id, index) => {
          links.push(
            <>
              <Link to={"/admin/" + fieldValue.categoryName + "/" + id}>
                {texts[index]}
              </Link>
              <div></div>
            </>
          );
        });
      }
      component = <Box>{links}</Box>;
      break;

    default:
      break;
  }

  return (
    <Container>
      <Stack direction={"row"} spacing={5}>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              width: "100px",
              minWidth: "100px",
              margin: "10px",
              textAlign: "right",
            }}
          >
            {field.label}
          </Typography>
          <Box sx={{ margin: "10px" }}>{component}</Box>
        </Box>
      </Stack>
    </Container>
  );
};

export const getCustomSelectList = (name, params = {}) => {
  const promise = new Promise((resolve = () => {}, reject = () => {}) => {
    switch (name) {
      case "category":
        fetchDataPaginated("CATEGORY_METADATA", [], "", 100)
          .then(({ data, lastDoc }) => {
            const formattedData = [];
            data.map((d) => {
              formattedData.push({
                text: d.singularName,
                value: d.id,
              });
            });
            resolve(formattedData);
          })
          .catch((error) => {
            reject(false);
          });
        break;
      case "categoryField":
        fetchDocumentById("CATEGORY_METADATA", params.categoryId)
          .then(({ fields }) => {
            if (fields) {
              const formattedData = [];
              fields.map((d) => {
                formattedData.push({
                  text: d.label,
                  value: d.name,
                });
              });
              resolve(formattedData);
            } else {
              reject(false);
            }
          })
          .catch((error) => {
            reject(false);
          });
        break;
      case "linkField":
        fetchDataPaginated(params.tableName, [], "", 100)
          .then(({ data, lastDoc }) => {
            const formattedData = [];
            data.map((d) => {
              formattedData.push({
                text: d[params.categoryField],
                value: d.id,
              });
            });
            resolve(formattedData);
          })
          .catch((error) => {
            reject(false);
          });
        break;
    }
  });

  return promise;
};

export const getDateFormat = (value) => {
  let currentDate = new Date(value);
  let day = ("0" + currentDate.getDate()).slice(-2);
  let month = ("0" + currentDate.getMonth() + 1).slice(-2);
  let year = currentDate.getFullYear();

  let fullDate = year + "-" + month + "-" + day;
  return fullDate;
};

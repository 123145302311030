import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { getCustomSelectList } from "../../admin/AdminGenUtility";
import { useLoading } from "../../../hooks/useLoading";

const CustomSelect = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const {
    required = false,
    options = [],
    value = field.multi ? [] : "",
    multi = false,
    disabled,
    delayedLoading = false,
    delayedLoadingParams: params,
  } = field;
  const { setIsLoading, setLoadingText } = useLoading();
  const [visibleOptions, setVisibleOptions] = useState([]);

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setVisibleOptions(options);
  }, [String(options)]);
  useEffect(() => {
    if (params) setVisibleOptions([]);
  }, [String(params)]);

  const onSelectListOpen = () => {
    if (
      delayedLoading &&
      (!options || options.length === 0) &&
      (!visibleOptions || visibleOptions.length === 0)
    ) {
      setIsLoading(true);
      getCustomSelectList(field.name, params).then(
        (data) => {
          setIsLoading(false);
          if (data) {
            setVisibleOptions(data);
          }
        },
        (data) => {
          setIsLoading(false);
        }
      );
    }
  };

  return (
    <Select
      variant="outlined"
      required={required}
      size="small"
      sx={{ minWidth: 220 }}
      onChange={handleOnChange}
      value={value}
      multiple={multi}
      disabled={disabled}
      onOpen={onSelectListOpen}
    >
      {visibleOptions.map((option) => (
        <MenuItem key={option.value || option} value={option.value || option}>
          {option.text || option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import NewLinkFormDialog from "./NewLinkFormDialog";
import { useParams } from "react-router-dom";
import CustomDataGrid from "../../AdminCommon/CustomDataGrid";

const LinkDetails = () => {
  const routeParams = useParams();
  const { category: category } = routeParams;
  const [openLinkForm, setOpenLinkForm] = useState(false);
  const handleNewLinkForm = () => {
    setOpenLinkForm(true);
  };
  return (
    <Container>
      <CustomDataGrid
        columnHeaders={[
          {
            field: "name",
            headerName: "Link to",
          },
        ]}
        data={[]}
        onRowClick={() => {}}
        hidePagination={true}
        hideSelectionColumn={true}
      />

      <NewLinkFormDialog
        openDialog={openLinkForm}
        setOpenDialog={setOpenLinkForm}
        category={category}
      />
    </Container>
  );
};
export default LinkDetails;

import { Box, Button, Modal, Typography, capitalize } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FIELD_TYPE_CATEGORY_FIELDS_LIST_FIELD,
  FIELD_TYPE_CATEGORY_LIST_FIELD,
  FIELD_TYPE_DYNAMIC_OPTION_FIELD,
  FIELD_TYPE_FIELDS,
  FIELD_TYPE_IS_FULLWIDTH_FIELD,
  FIELD_TYPE_IS_MULTI_FIELD,
  FIELD_TYPE_IS_URL_FIELD,
} from "./FieldTypeOptions";
import { getComponent } from "../../AdminGenUtility";
import { FIELD_TYPES } from "../../AdminAppConstant";
import { useAdminContext } from "../../../../context/admin-context";

const ManageFieldPopupForm = (props) => {
  const { open, setOpen, onSave = () => {}, editingMode, field = {} } = props;
  const adminContext = useAdminContext();

  const [visibleFields, setVisibleFields] = useState([]);
  const [fieldType, setFieldType] = useState("");
  const [data, setData] = useState(field);
  const [categoryId, setCategoryId] = useState("");
  const [categoryObj, setCategoryObj] = useState({});

  useEffect(() => {
    if (field) {
      setData(field);
      setFieldType(field.type);
    } else {
      setData({
        type: "text",
      });
      setFieldType("text");
    }
  }, [open, String(field)]);

  useEffect(() => {
    const fieldObj = FIELD_TYPES.find((fld) => fld.value === fieldType);

    const fieldsToShow = [...FIELD_TYPE_FIELDS];

    if (fieldObj) {
      if (fieldObj.showCategoryList) {
        fieldsToShow.push(FIELD_TYPE_CATEGORY_LIST_FIELD);
      }

      if (fieldObj.showCategoryFields) {
        fieldsToShow.push(FIELD_TYPE_CATEGORY_FIELDS_LIST_FIELD);
      }

      if (fieldObj.showFullWidth) {
        fieldsToShow.push(FIELD_TYPE_IS_FULLWIDTH_FIELD);
      }

      if (fieldObj.showMulti) {
        const obj = { ...FIELD_TYPE_IS_MULTI_FIELD };
        obj.text = fieldType === "text" ? "Multiline Text" : "Multi Selection";
        if (fieldType === "customField") obj.text = "Multiple Entries";
        fieldsToShow.push(obj);
      }

      if (fieldObj.showOptions) {
        fieldsToShow.push(FIELD_TYPE_DYNAMIC_OPTION_FIELD);
      }

      if (fieldObj.showAsUrl) {
        fieldsToShow.push(FIELD_TYPE_IS_URL_FIELD);
      }

      if (fieldObj.readOnlyFields) {
        fieldObj.readOnlyFields.map((fieldName) => {
          const index = fieldsToShow.findIndex((fld) => fld.name === fieldName);
          if (index > -1) {
            fieldsToShow[index].disabled = true;
          }
        });
      }
    }

    setVisibleFields(fieldsToShow);
    setData({ type: fieldType });
  }, [fieldType]);

  useEffect(() => {
    let fieldsToShow = [...visibleFields];
    editingMode &&
      fieldsToShow.map((fld) => {
        delete fld.disabled;
        if (fld.name === "label") {
          fld.disabled = true;
        }
        if (fld.name === "type") {
          fld.disabled = true;
        }
      });

    setVisibleFields(fieldsToShow);
  }, [editingMode]);

  useEffect(() => {
    adminContext.getCategory(categoryId, true).then(
      (response) => {
        setCategoryObj(response.responseData);
      },
      (response) => {
        console.log(response.message);
      }
    );
  }, [categoryId]);

  const handleOnChange = (fieldName, value) => {
    if (fieldName) {
      setData({ ...data, [fieldName]: value });
    }

    if (fieldName === "type") {
      setFieldType(value);
    }

    if (fieldName === "category") {
      setCategoryId(value);
      let delayedLoadingParams = { categoryId: value };
      const tempFields = [...visibleFields];
      const fieldIndex = tempFields.findIndex((fld) => {
        return fld.name == "categoryField";
      });

      if (fieldIndex > -1) {
        tempFields[fieldIndex].delayedLoadingParams = delayedLoadingParams;
        setVisibleFields(tempFields);
      }
    }
    if (fieldName === "categoryField") {
      let defaultFieldLabel = categoryObj.singularName + " " + value;
      let preData = { ...data, label: defaultFieldLabel, [fieldName]: value };
      setData(preData);
    }
  };
  const handleOnSaveClick = (e) => {
    e.preventDefault();
    const dataToSave = { ...data };
    if (fieldType === "linkField") {
      let linkFieldLabel =
        categoryObj.singularName +
        " " +
        dataToSave.categoryField.charAt(0).toUpperCase() +
        dataToSave.categoryField.slice(1).toLowerCase().replaceAll("_", " ");
      dataToSave.label = linkFieldLabel;
    }
    let name = dataToSave.label.trim().toLowerCase().replaceAll(" ", "_");
    dataToSave.name = name;
    console.log("pppppppppp",dataToSave);
    onSave(dataToSave);
    setOpen(false);
  };

  if (!open) return;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#FFFFFF",
          p: 4,
        }}
      >
        <form
          style={{ backgroundColor: "#ffffff" }}
          onSubmit={handleOnSaveClick}
        >
          <Box sx={{ backgroundColor: "#1976d2", padding: "10px" }}>
            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", color: "#FFFFFF" }}
            >
              {editingMode
                ? "Editing Field - " + field.label
                : "Create New Field"}
            </Typography>
          </Box>

          <Box sx={{ paddingY: 2, overflowY: "auto", maxHeight: "400px" }}>
            {visibleFields.map((field) => {
              let newField = { ...field };
              newField.value = data[field.name];

              return getComponent(newField, {
                onChange: (value) => handleOnChange(field.name, value),
              });
            })}
          </Box>

          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{ margin: "10px" }}
            >
              Cancel
            </Button>
            <Button variant="contained" sx={{ margin: "10px" }} type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
export default ManageFieldPopupForm;

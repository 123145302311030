import AdminWrapper from "../AdminCommon/AdminWrapper";
import { useNavigate, useParams } from "react-router-dom";
import CustomToolbarActions from "../AdminCommon/CustomToolbarActions";
import { Edit, Delete } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useAdminContext } from "../../../context/admin-context";
import { useEffect, useState } from "react";
import { getToViewComponent } from "../AdminGenUtility";
import { useLoading } from "../../../hooks/useLoading";
import LogoutConfirmDialog from "../AdminCommon/LogoutConfirmDialog";

const ViewObject = (props) => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();
  const { titleFieldName = "title" } = props;
  const { setIsLoading, setLoadingText } = useLoading();

  const routeParams = useParams();
  const { categoryName: objectType, id: objectId } = routeParams;
  const [viewData, setViewData] = useState({});
  const [fieldName, setFieldName] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    adminContext.getObjectFields(objectType).then(
      (response) => {
        const { success, responseData, message } = response;
        if (success) {
          setFieldName(responseData);
        } else {
          console.log(message);
        }
      },
      (response) => {
        const { success, responseData, message } = response;
        console.log(message);
      }
    );

    adminContext.getObject(objectType, objectId).then(
      (response) => {
        const { success, responseData, message } = response;
        if (success) {
          setViewData(responseData || []);
        } else {
          console.log(message);
        }
      },
      (response) => {
        const { success, responseData, message } = response;
        console.log(message);
      }
    );
  }, [objectId]);

  const handleOnEditClick = () => {
    navigate("/admin/" + objectType + "/edit/" + objectId);
  };

  const handleOnDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleOnDeleteConfirm = () => {
    setIsLoading(true);
    adminContext.deleteObject(objectType, objectId).then(
      (response) => {
        setIsLoading(false);
        setLoadingText(
          response.message
          // "Deleting" + viewData[titleFieldName] + ", please wait..."
        );
        navigate(-1);
      },
      (response) => {
        setIsLoading(false);
        console.log(response.message);
      }
    );
    setOpenDialog(false);
  };

  const handleOnCancel = () => {
    setOpenDialog(false);
  };

  const actionList = [
    { text: "Edit", icon: <Edit />, onClick: handleOnEditClick },
    { text: "Delete", icon: <Delete />, onClick: handleOnDeleteClick },
  ];

  return (
    <>
      <AdminWrapper>
        <CustomToolbarActions
          title={"Details of " + viewData[titleFieldName]}
          actions={actionList}
          showBackButton={true}
          onBackButtonClick={() => {
            navigate(-1);
          }}
        />
        <Stack direction="row" spacing={9}>
          <Box>
            {fieldName.map((field) => {
              const value = viewData[field.name];

              if (value && field.type === "linkField") {
                value.categoryName = adminContext.getCategoryNameById(
                  value.categoryId
                );
              }

              return <Box>{getToViewComponent(field, value)}</Box>;
            })}
          </Box>
        </Stack>
      </AdminWrapper>
      <LogoutConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title="Delete"
        contentText="Are you sure you want to Delete?"
        onConfirm={handleOnDeleteConfirm}
        onCancel={handleOnCancel}
      />
    </>
  );
};

export default ViewObject;

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

/**
 * Fetches data from Firestore with pagination and sorting.
 * @param {string} collectionName - Name of the collection.
 * @param {Array<string>} fields - Fields to include in the result.
 * @param {string} orderByField - Field to sort by.
 * @param {number} pageSize - Number of documents per page.
 * @param {DocumentSnapshot} lastDoc - Last document from the previous page, null for the first page.
 * @returns {Promise<{data: Array<Object>, lastDoc: DocumentSnapshot}>}
 */
export const fetchDataPaginated = async (
  collectionName,
  fields,
  orderByField,
  pageSize,
  lastDoc = null
) => {
  try {
    let q = query(
      collection(db, collectionName),
      limit(pageSize),
      ...(lastDoc ? [startAfter(lastDoc)] : [])
    );

    if (orderByField) {
      q = query(
        collection(db, collectionName),
        orderBy(orderByField),
        limit(pageSize),
        ...(lastDoc ? [startAfter(lastDoc)] : [])
      );
    }
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      if (!fields || fields.length === 0) return { ...data, id: doc.id };
      return fields.reduce((obj, field) => {
        if (data[field] !== undefined) {
          obj[field] = data[field];
        }
        return { ...obj, id: doc.id };
      }, {});
    });

    const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { data, lastDoc: newLastDoc };
  } catch (error) {
    console.error("Error fetching paginated data: ", error);
    throw error;
  }
};

/**
 * Fetches a document from Firestore by its ID.
 * @param {string} collectionName - Name of the collection.
 * @param {string} documentId - The ID of the document to retrieve.
 * @returns {Promise<Object|null>}
 */
export const fetchDocumentById = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
};

/**
 * Fetches a single document from Firestore based on a specific field.
 * @param {string} collectionName - Name of the collection.
 * @param {string} fieldName - The field to query against.
 * @param {any} fieldValue - The value of the field to match.
 * @returns {Promise<Object|null>}
 */
export const fetchSingleDocumentByField = async (
  collectionName,
  fieldName,
  fieldValue
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(fieldName, "==", fieldValue),
      limit(1)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No document matches the criteria.");
      return null;
    } else {
      const doc = querySnapshot.docs[0].data();
      return doc;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
};

/**
 * Creates a new document in Firestore.
 * @param {string} collectionName - Name of the collection.
 * @param {Object} data - Data to be added to the new document.
 * @param {string|null} documentId - Optional ID for the new document. If null, Firestore generates an ID.
 * @returns {Promise<string>} - Returns the ID of the created document.
 */
export const createNewDocument = async (
  collectionName,
  data,
  documentId = null
) => {
  try {
    if (documentId) {
      // If a document ID is provided, create a document with that ID
      const docRef = doc(db, collectionName, documentId);
      await setDoc(docRef, data);
      return documentId;
    } else {
      // If no document ID is provided, let Firestore generate an ID
      const docRef = await addDoc(collection(db, collectionName), data);
      return docRef.id;
    }
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

/**
 * Updates an existing document in Firestore.
 * @param {string} collectionName - Name of the collection.
 * @param {string} documentId - ID of the document to update.
 * @param {Object} updatedData - Updated data for the document.
 * @returns {Promise<void>}
 */
export const updateExistingDocument = async (
  collectionName,
  documentId,
  updatedData
) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await updateDoc(docRef, updatedData);
    console.log("Document successfully updated");
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

/**
 * Deletes a document from Firestore.
 * @param {string} collectionName - Name of the collection.
 * @param {string} documentId - ID of the document to delete.
 * @returns {Promise<void>}
 */
export const deleteDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await deleteDoc(docRef);
    console.log("Document successfully deleted");
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};

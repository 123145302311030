import { Typography } from "@mui/material";

const FormDetails = () => {
  return (
    <>
      <Typography variant="h7"> Forms</Typography>
    </>
  );
};
export default FormDetails;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../../context/admin-context";
import AdminWrapper from "../../AdminCommon/AdminWrapper";
import CustomToolbarActions from "../../AdminCommon/CustomToolbarActions";
import CustomDataGrid from "../../AdminCommon/CustomDataGrid";
import { useLoading } from "../../../../hooks/useLoading";

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  {
    field: "updatedAt",
    headerName: "Last Modified",
    width: 200,
  },
];

const CategoryList = () => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();
  const [data, setData] = useState([]);
  const { setIsLoading } = useLoading();

  useEffect(() => {
    setIsLoading(true);
    adminContext.getCategoryList().then((response) => {
      setIsLoading(false);
      setData(response.responseData);
    });
  }, []);

  const handleOnRowClick = (data) => {
    const { row } = data;
    // navigate("/admin/settings/manage-categories/" + row.name);
    navigate("/admin/settings/manage-categories/" + row.name);
  };

  return (
    <AdminWrapper>
      <CustomToolbarActions title="All Categories" />

      <CustomDataGrid
        columnHeaders={columns}
        data={data}
        onRowClick={handleOnRowClick}
      />
    </AdminWrapper>
  );
};

export default CategoryList;

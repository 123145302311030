import { Box, Link } from "@mui/material";

const Home = () => {
  return (
    <Box>
      <h1>RentOCamera Services</h1>
      <Link href="admin/admin-dashboard">Go Admin Home</Link>
    </Box>
  );
};

export default Home;

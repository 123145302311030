import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = (props) => {
  const { columnHeaders = [], data = [], pageSize = 5, onRowClick } = props;

  return (
    <DataGrid
      rows={data}
      columns={columnHeaders}
      onRowClick={onRowClick}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      pageSizeOptions={[5]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
};
export default CustomDataGrid;

import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { getCustomSelectList } from "../../admin/AdminGenUtility";
import { useLoading } from "../../../hooks/useLoading";
import { useAdminContext } from "../../../context/admin-context";

const CustomLinkSelect = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const {
    required = false,
    options = [],
    value = {},
    multi = false,
    disabled,
    delayedLoading = true,
    delayedLoadingParams: params,
    category,
    categoryField,
  } = field;
  const { setIsLoading, setLoadingText } = useLoading();
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [defaultSelect, setDefaultSelect] = useState({});
  const adminContext = useAdminContext();

  const tableName = adminContext.getTableNameByCatId(category);

  // console.log("MulipleSelect", field);

  useEffect(() => {
    setVisibleOptions(options);
  }, [String(options)]);
  useEffect(() => {
    if (params) setVisibleOptions([]);
  }, [String(params)]);


  const handleOnChange = (event) => {
    let id = event.target.value;
    let text = "";

    if (multi) {
      let idList = [];
      let textList = [];
      event.target.value.map((tempId) => {
        if (tempId) {
          idList.push(tempId);
          textList.push(getName(tempId));
        }
      });

      id = idList.join(",");
      text = textList.join(",");
    } else {
      text = getName(id);
    }

    onChange({
      id: id, //id of the selected object(s)
      text: text, //visible text of the selected object(s)
      categoryId: category, //category id of that object
    });
  };

  const onSelectListOpen = () => {
    if (
      delayedLoading &&
      (!options || options.length === 0) &&
      (!visibleOptions || visibleOptions.length === 0)
    ) {
      setIsLoading(true);
      getCustomSelectList(field.type, {
        ...params,
        category,
        categoryField,
        tableName,
      }).then(
        (data) => {
          setIsLoading(false);
          if (data) {
            setVisibleOptions(data);
          }
        },
        (data) => {
          setIsLoading(false);
        }
      );
    }
  };

  const getName = (id) => {
    const index = visibleOptions.findIndex((rec) => rec.value == id);
    if (index != -1) return visibleOptions[index].text;
  };

  let tmpValue = value.id;

  if (multi) {
    tmpValue = value.id ? value.id.split(",") : [];
  }

  return (
    <Select
      variant="outlined"
      required={required}
      size="small"
      sx={{ minWidth: 220 }}
      onChange={handleOnChange}
      value={tmpValue}
      multiple={multi}
      disabled={disabled}
      onOpen={onSelectListOpen}
    >
      {visibleOptions.map((option) => (
        <MenuItem key={option.value || option} value={option.value || option}>
          {option.text || option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomLinkSelect;

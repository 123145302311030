import { Box, Container } from "@mui/material";
import LeftPanel from "../AdminHome/LeftPanel";
import AdminHeader from "../AdminHeader/AdminHeader";

const AdminWrapper = (props) => {
  return (
    <Box sx={{ flex: 1 }}>
      <AdminHeader />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          paddingTop: "70px",
          position: "fixed",
          width: "100%",
        }}
      >
        <LeftPanel sx={{ height: "calc(100vh - 70px)" }} />
        <Box
          sx={{
            flexGrow: 1,
            ...props.sx,
            height: "calc(100vh - 70px)",
            overflow: "scroll",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminWrapper;

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../../context/admin-context";
import { useLoading } from "../../../../hooks/useLoading";
import AdminWrapper from "../../AdminCommon/AdminWrapper";
import CustomToolbarActions from "../../AdminCommon/CustomToolbarActions";
import CustomDataGrid from "../../AdminCommon/CustomDataGrid";

const SettingListTitles = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];
const FieldTypeList = () => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();

  const { setIsLoading, setLoadingText } = useLoading();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(SettingListTitles);

  useEffect(() => {
    loadDataList();
    setColumns(SettingListTitles);
  }, []);

  const loadDataList = (loadData) => {
    setIsLoading(true);
    setLoadingText("Loading Data, please wait...");
    setData([]);
    adminContext.getFieldTypeList(null, loadData).then(
      (response) => {
        setIsLoading(false);
        const { success, message, responseData } = response;
        if (success) {
          setData(responseData);
        } else {
          console.log(message);
        }
      },
      (response) => {}
    );
  };

  const handleOnRowClick = (data) => {
    const { id } = data;
    navigate("/admin/settings/manage-type/" + id);
  };
  const handleSearch = () => {};
  const handleNewButton = () => {
    navigate("/admin/settings/manage-type/new-type");
  };
  const handleRefreshClick = () => {};

  const SettingActionList = [
    { text: "search", icon: <SearchIcon />, onClick: handleSearch },
    { text: "New", icon: <AddIcon />, onClick: handleNewButton },
    { text: "", icon: <RefreshIcon />, onClick: handleRefreshClick },
  ];

  return (
    <AdminWrapper>
      <CustomToolbarActions
        title="Custom Type List"
        actions={SettingActionList}
      />

      <CustomDataGrid
        columnHeaders={columns}
        data={data}
        onRowClick={handleOnRowClick}
      />
    </AdminWrapper>
  );
};

export default FieldTypeList;

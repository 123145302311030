import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import AdminWrapper from "../AdminCommon/AdminWrapper";
import CustomToolbarActions from "../AdminCommon/CustomToolbarActions";
import CustomDataGrid from "../AdminCommon/CustomDataGrid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../../../hooks/useLoading";
import { useAdminContext } from "../../../context/admin-context";

const ObjectList = (props) => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();
  const routeParams = useParams();
  const { categoryName: objectType } = routeParams;

  const { setIsLoading, setLoadingText } = useLoading();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    loadObjectData();
    loadObjectColumns();
  }, [objectType]);

  const loadObjectData = (reload) => {
    setIsLoading(true);
    setLoadingText("Loading " + objectType + ", please wait...");
    setData([]);

    adminContext.getObjectList(objectType, null, reload).then(
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        if (success) {
          setData(responseData);
        } else {
          console.log(message);
        }
      },
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        console.log(message);
      }
    );
  };

  const loadObjectColumns = () => {
    adminContext.getObjectFields(objectType).then(
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        if (success) {
          let newData = responseData;
          newData.map((f) => {
            f.headerName = f.label;
            f.field = f.name;
            switch (f.type) {
              case "richText":
                f.renderCell = (value) => {
                  return value.value ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: value.value }}
                    ></div>
                  ) : (
                    ""
                  );
                };
                break;
              case "date":
                f.valueGetter = (value) => {
                  return value.value ? new Date(value.value) : "";
                };
                break;
              case "image":
                f.renderCell = (params) => {
                  return params.value ? (
                    <img
                      src={params.value}
                      alt={params.value}
                      style={{
                        width: "50px",
                        height: "50px",
                        // borderRadius: "50px",
                      }}
                    />
                  ) : (
                    ""
                  );
                };
                break;
              case "linkField":
                f.renderCell = ({ value }) => {

                  if (!value) return "";

                  const categoryName = adminContext.getCategoryNameById(
                    value.categoryId
                  );
                  return (
                    <Link to={"/admin/" + categoryName + "/" + value.id}>
                      {value.text}
                    </Link>
                  );
                };
            }
          });
          setColumns(newData);
        } else {
          console.log(message);
        }
      },
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        console.log(message);
      }
    );
  };

  const handleSearch = () => {};
  const handleNewButton = () => {
    navigate("/admin/" + objectType + "/new");
  };
  const handleRefreshClick = () => {
    loadObjectData(true);
  };
  const actionList = [
    { text: "search", icon: <SearchIcon />, onClick: handleSearch },
    { text: "New", icon: <AddIcon />, onClick: handleNewButton },
    { text: "", icon: <RefreshIcon />, onClick: handleRefreshClick },
  ];

  const handleOnRowClick = (data) => {
    const { id, row } = data;
    navigate("/admin/" + objectType + "/" + id);
  };

  return (
    <AdminWrapper>
      <CustomToolbarActions title={"All " + objectType} actions={actionList} />
      <CustomDataGrid
        columnHeaders={columns}
        data={data}
        onRowClick={handleOnRowClick}
      />
    </AdminWrapper>
  );
};

export default ObjectList;

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TYPES } from "../AdminAppConstant";
import ManageForm from "../AdminCommon/ManageForm";
import { useAdminContext } from "../../../context/admin-context";
import { useLoading } from "../../../hooks/useLoading";

const NewObject = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const adminContext = useAdminContext();
  const [otherInfoFields, setOtherInfoFields] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [fields, setFields] = useState([]);

  const routeParams = useParams();
  const { categoryName: objectType } = routeParams;

  useEffect(() => {
    adminContext.getOtherInformationFields(selectedType).then((response) => {
      const { responseData, success, message } = response;
      if (success) {
        setOtherInfoFields(responseData);
      }
    });
    loadObjectFields();
  }, [selectedType]);

  const loadObjectFields = () => {
    setIsLoading(true);
    adminContext.getObjectFields(objectType).then(
      (response) => {
        setIsLoading(false);
        const { success, responseData, message } = response;
        if (success) {
          setFields(responseData);
        } else {
          console.log(message);
        }
      },
      (response) => {
        setIsLoading(false);
        console.log(response.message);
      }
    );
  };

  const handleOnCancelClick = () => {
    navigate(-1);
  };

  const handleOnSaveClick = (data) => {
    setIsLoading(true);
    adminContext.createObject(objectType, data).then(
      (response) => {
        setIsLoading(false);
        console.log(response);
        navigate(-1);
      },
      (response) => {
        setIsLoading(false);
        console.log(response);
      }
    );
  };

  const handleOnFieldChange = (fieldName, value) => {
    if (fieldName === TYPES.SELECT_LIST_CUSTOM_TYPE) {
      setSelectedType(value);
    }
  };

  return (
    <ManageForm
      title={"New " + objectType}
      fieldSections={[
        {
          fields: fields,
        },
        {
          header: "Other Information",
          fields: otherInfoFields,
        },
      ]}
      onSubmit={handleOnSaveClick}
      onCancel={handleOnCancelClick}
      onFieldValueChange={handleOnFieldChange}
    />
  );
};

export default NewObject;

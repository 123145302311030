import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";

const CustomRichTextEditor = (props) => {
  const { field = {}, onChange = () => {} } = props;
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (editor) editor.setData(field.value || "");
  }, [editor, field.value]);

  const handleOnChange = () => {
    let data = editor.getData();
    if (data !== field.value) onChange(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data="<p>Hello from CKEditor&nbsp;5!</p>"
      onReady={(currentEditor) => setEditor(currentEditor)}
      // onChange={handleOnChange}
      onBlur={handleOnChange}
    />
  );
};

export default CustomRichTextEditor;

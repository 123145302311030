import { AppBar, Avatar, Box, Toolbar, Typography } from "@mui/material";

const AdminHeader = () => {
  return (
    <AppBar>
      <Toolbar>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          RentOCamera Admin Panel
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Avatar alt="avatar" sx={{ bgcolor: "orange", color: "black" }}>
          MS
        </Avatar>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;

import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Toolbar, Typography } from "@mui/material";

const CustomToolbarActions = (props) => {
  const { title, actions = [], showBackButton, onBackButtonClick } = props;

  return (
    <Toolbar sx={{ backgroundColor: "#f8f9f9" }}>
      {showBackButton && onBackButtonClick && (
        <IconButton onClick={onBackButtonClick}>
          <ArrowBack />
        </IconButton>
      )}
      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#21618C"
        fontWeight="bold"
        sx={{
          flexGrow: 1,
          display: { xs: "none", sm: "block" },
        }}
      >
        {title}
      </Typography>
      {actions.map((action, index) => (
        <Box>
          <Button
            type={action.type}
            onClick={action.onClick}
            startIcon={action.icon}
          >
            {action.text}
          </Button>
        </Box>
      ))}
    </Toolbar>
  );
};
export default CustomToolbarActions;

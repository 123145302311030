import { TextField } from "@mui/material";
import { useState } from "react";

const CustomImage = (props) => {
  const { field, onChange } = props;
  const { required = false, value = "" } = field;
  const [selectedImg, setSelectedImg] = useState(null);

  const handleOnChange = (event) => {
    // console.log("image File", event.target.files[0].name);
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      // console.log("onloadend", fileReader.result);
      setSelectedImg(fileReader.result);
      onChange(fileReader.result);
    };
    if (file) {
      fileReader.readAsDataURL(file);
    } else {
      setSelectedImg(null);
    }
  };

  return (
    <>
      <TextField
        type={"file"}
        variant="outlined"
        required={required}
        size="small"
        // value={value}
        onChange={handleOnChange}
        inputProps={{ accept: "image/*" }}
      />
      {/* Just to view selected img */}
      {value && <img src={value} alt="customImg" style={{ width: "50px" }} />}
    </>
  );
};

export default CustomImage;

export const TYPES = {
  TEXT: "text",
  RICH_TEXT: "richText",
  NUMBER: "number",
  DATE: "date",
  CHECKBOX: "checkbox",
  CHECKBOX_GROUP: "checkboxGroup",
  RADIO_GROUP: "radioGroup",
  SELECT_LIST: "selectList",
  CUSTOM_FIELD: "customField",

  DYNAMIC_OPTIONS: "dynamicOptions",
  SELECT_LIST_CUSTOM_TYPE: "selectListType",
  IMAGE: "image",
};

export const FIELDS_TO_CONSIDER_WHILE_SAVING = [
  "type",
  "name",
  "label",
  "required",
  "fullWidth",
  "multi",
  "options",
  "isUrl",
  "category",
  "categoryField",
];

export const FIELD_TYPES = [
  {
    value: "text",
    text: "Text",
    showMulti: true,
    showFullWidth: true,
    id: 1001,
  },
  { value: "richText", text: "Rich Text", showFullWidth: true, id: 1002 },
  { value: "number", text: "Number", id: 1003 },
  { value: "date", text: "Date", id: 1004 },
  { value: "image", text: "Image", id: 10004 },
  { value: "checkbox", text: "Checkbox", id: 1005 },
  {
    value: "checkboxGroup",
    text: "Select List - Checkboxes",
    showOptions: true,
    id: 1006,
  },
  {
    value: "radioGroup",
    text: "Select List - Radios",
    showOptions: true,
    id: 1007,
  },
  {
    value: "selectList",
    text: "Select List - Dropdown",
    showOptions: true,
    showMulti: true,
    id: 1008,
  },
  {
    value: "customField",
    text: "Custom Field",
    showMulti: true,
    showAsUrl: true,
    showFullWidth: true,
    id: 1009,
  },
  {
    value: "linkField",
    text: "Link Field",
    showMulti: true,
    showCategoryList: true,
    showCategoryFields: true,
    readOnlyFields: ["label"],
    id: 1010,
  },
];

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ManageForm from "../../AdminCommon/ManageForm";
import { TYPES } from "../../AdminAppConstant";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ManageFieldPopupForm from "./ManageFieldPopupForm";
import { useAdminContext } from "../../../../context/admin-context";
import { useLoading } from "../../../../hooks/useLoading";

const NewFieldType = () => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();
  const { setIsLoading, setLoadingText } = useLoading();

  const [showCreateFieldWindow, setShowCreateFieldWindow] = useState(false);
  const [fields, setFields] = useState([]);
  const titleField = {
    name: "title",
    label: "Title",
    type: TYPES.TEXT,
    required: true,
  };

  const handleOnCancelClick = () => {
    navigate(-1);
  };

  const handleOnSaveClick = (data) => {
    if (!data.title) {
      alert("Title is required.");
      return;
    }
    setIsLoading(true);
    setLoadingText("Saving field type, please wait...");

    const dataToPost = {
      title: data.title,
      fields: fields,
    };

    adminContext.createFieldType(dataToPost).then(
      () => {
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const handleOnFieldChange = (fieldName, value) => {};

  const handleOnNewFieldCreate = (field) => {
    setFields([...fields, field]);
  };

  return (
    <>
      <ManageForm
        title="New Field Type"
        fieldSections={[{ fields: [titleField, ...fields] }]}
        onSave={handleOnSaveClick}
        onCancel={handleOnCancelClick}
        onFieldValueChange={handleOnFieldChange}
      >
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 2 }}>
          <Button
            type="button"
            startIcon={<AddIcon />}
            sx={{ borderRadius: 2 }}
            variant="outlined"
            onClick={() => setShowCreateFieldWindow(true)}
          >
            Create New Field
          </Button>
        </Box>
      </ManageForm>
      <ManageFieldPopupForm
        open={showCreateFieldWindow}
        setOpen={setShowCreateFieldWindow}
        onSave={handleOnNewFieldCreate}
      />
    </>
  );
};

export default NewFieldType;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const NewLinkFormDialog = (props) => {
  const { openDialog, setOpenDialog, category } = props;

  const handleOnClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={handleOnClose}>
      <DialogTitle>Add New Link To {category}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* Are you sure you want to logout? */}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={() => setOpenDialog(false)}>
          Cancel
        </Button>
        <Button variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewLinkFormDialog;
